<template src="./templates/oprosnik-tehnika.html"></template>
<script>

import FormInput from '@f/Input';
import FormButton from '@f/Button';
import FormCheckbox from '@f/Check';
import Multiselect from '@f/Multiselect';
import List from '@c/List/index';
import Modal from '@c/Modal/index';
import helpers from '@/tools/helpers';
import {
    clearQuestionnaireFeed,
    delQuestionnaire,
    delQuestionnaireCategory,
    getQuestionnaire,
    getQuestionnaireCategories, getQuestionnaireCategoryFeed, importQuestionnaireFeed, setQuestionnaire,
    setQuestionnaireCategory
} from '@api/questionnaire';
import NewCategory from './NewCategory';
import FormQuestionnaire from './components/FormQuestionnaire';
import questionnaireTemplate from './templates/questionnaireTemplate';


export default {
    name: 'OprosnikTehnika',
    components: {
        Multiselect,
        FormInput,
        FormButton,
        List,
        NewCategory,
        FormCheckbox,
        Modal,
        FormQuestionnaire,
    },
    props: {
        categoryTypes: {
            type: Object,
            default() {
                return {};
            }
        },
        categoryType: {
            type: String,
            default() {
                return '';
            }
        },
        action: {
            type: String,
            default() {
                return null;
            }
        }
    },
    data() {
        return {
            categoryFeed: [],
            showModal: false,
            newCategory: false,
            editCategory: {
                action: '',
                id: '',
                name: '',
                disabled: '',
                category: null,
            },
            showQuestionnaireModal: false,
            editedQuestionnaire: {},
            searchString: '',
            searchEnabled: false,
        };
    },
    computed: {
        searchStringPrepared() {
            return this.searchString.trim();
        },
        showImportButton() {
            return this.$route.query.magic_query === '1';
        },
        multiselectCategory() {
            return this.categoryTypes.find((item) => this.editCategory.category === item.id)?.name;
        },
        isEditor() {
            return this.action === 'edit';
        },
        disabledCheckbox: {
            get() {
                return !!this.editCategory.disabled;
            },
            set(val) {
                this.editCategory.disabled = val;
            }

        },
        disabledWrite() {
            return Number(this.editCategory.disabled).toString();
        },

        categories() {
            const categoriesArr = this.categoryFeed;
            const hasQuestionnaires = ({questionnaires}) => (
                    typeof questionnaires !== 'undefined' && questionnaires.length
            );

            if (categoriesArr instanceof Array) {
                const categories = [];
                categoriesArr.forEach((item) => {
                    item.categories.forEach((item2) => {
                        categories.push(item2);
                    });
                });
                categoriesArr.categories = categories;
            }

            if (categoriesArr.categories?.length) {

                const result = {};

                for (let i = 0; i < categoriesArr.categories.length; i++) {
                    if (!hasQuestionnaires(categoriesArr.categories[i]) && this.searchEnabled) {
                        // eslint-disable-next-line no-continue
                        continue;
                    }

                    result[i] = {
                        ...categoriesArr.categories[i],
                        title: categoriesArr.categories[i].name,
                    };

                    if (hasQuestionnaires(result[i])) {

                        result[i].children = result[i].questionnaires;

                        for (let j = 0; j < result[i].children.length; j++) {
                            result[i].children[j].title = result[i].children[j].name;
                            result[i].children[j].url = this.isEditor
                                ? `/questionnaire/${result[i].children[j].id}/questions/`
                                : `/questionnaire2/new/${result[i].children[j].id}`;
                        }
                    }
                }

                return Object.values(result);
            }

            return [];
        },
    },
    watch: {
        categoryType: {
            immediate: true,
            handler(val, oldVal) {
                this.initCategoryFeed();
            }
        },
        searchString: {
            immediate: true,
            handler(val, oldVal) {
                this.searchEnabled = this.searchStringPrepared.length >= 3
                    || (val?.length < oldVal?.length);

                if (this.searchEnabled) {
                    this.initCategoryFeed();
                }
            }
        },
    },
    created() {
        this.initCategoryFeed();
    },

    methods: {
        async initCategoryFeed() {
            this.categoryFeed = await getQuestionnaireCategoryFeed(
                {
                    categoryType: this.categoryType,
                    searchString: this.searchStringPrepared,
                }
            );
        },

        selectCategory(value) {
            this.editCategory.category = value.id;
        },

        async runAction(id, action) {
            switch (action) {
                case 'edit': {
                    this.$slots.body = true;
                    this.showModal = true;
                    this.newCategory = false;

                    const categories = await getQuestionnaireCategories(id);

                    this.editCategory.id = categories.id;
                    this.editCategory.name = categories.name;
                    this.editCategory.disabled = categories.disabled;
                    this.editCategory.category = categories.category_type;
                    break;
                }

                case 'del': {
                    if (window.confirm('Вы уверены, что хотите удалить категорию опроса?')) {
                        await delQuestionnaireCategory(id);
                        await this.initCategoryFeed();
                    }
                    break;
                }

                case 'save': {
                    await setQuestionnaireCategory({
                        id: this.editCategory.id,
                        name: this.editCategory.name,
                        disabled: this.disabledWrite,
                        type: this.editCategory.category
                    });
                    this.showModal = false;
                    await this.initCategoryFeed();
                    break;
                }

                case 'add': {
                    await setQuestionnaireCategory({
                        id: this.editCategory.id,
                        name: this.editCategory.name,
                        disabled: this.disabledWrite,
                        type: this.editCategory.category
                    });
                    this.showModal = false;
                    await this.initCategoryFeed();
                    break;
                }

                case 'open': {
                    this.$slots.body = true;
                    this.showModal = true;
                    this.newCategory = true;
                    this.editCategory.id = null;
                    this.editCategory.name = '';
                    this.editCategory.disabled = 0;
                    this.editCategory.category = null;
                    break;
                }

                case 'icon-click': {
                    await this.editQuestionnaire(null, id);
                    break;
                }

                case 'edit-sub': {
                    await this.editQuestionnaire(id);
                    break;
                }

                case 'del-sub': {
                    if (window.confirm('Вы уверены, что хотите удалить опрос?')) {
                        await this.removeQuestionnaire(id);
                    }
                    break;
                }

                default:
                    console.log(`Unknown action: ${action}`);
            }
        },

        async importFeed(e) {
            e.stopPropagation();
            console.log('import...');
            await importQuestionnaireFeed();
        },
        async clearFeed(e) {
            e.stopPropagation();
            console.log('clear...');
            await clearQuestionnaireFeed();
        },

        clickOnList() {},

        hideQuestionnaireModal() {
            this.showQuestionnaireModal = false;
        },

        async editQuestionnaire(id, categoryId) {
            if (id !== null) {
                this.editedQuestionnaire = await getQuestionnaire(id);
            } else {
                this.editedQuestionnaire = helpers.deepCopy(questionnaireTemplate);
                this.editedQuestionnaire.category_id = categoryId;
            }
            this.showQuestionnaireModal = true;
        },

        async saveQuestionnaire() {
            await setQuestionnaire(this.editedQuestionnaire);
            this.showQuestionnaireModal = false;
            await this.initCategoryFeed();
        },
        async removeQuestionnaire(id) {
            await delQuestionnaire(id);
            await this.initCategoryFeed();
        },
    }
};
</script>
