<div>
    <div class="row c mt-4">
        <div class="row-col w100">
            <div class="col">
                <FormInput
                    v-model="searchString"
                    style="width: 50%"
                    :attributes="{placeholder:'Поиск по категориям и опросникам'}"
                />
            </div>
            <div v-if="action === 'edit'" class="col-auto">
                <FormButton @click="runAction(null, 'open')" label="Добавить группу"
                            :attributes="{class:['btn-blue', 'h36']}"/>
            </div>
        </div>
    </div>
    <div v-if="isEditor && showImportButton" class="row c mt-4">
        <div class="row-col">
            <div class="col-auto">
                <FormButton label="Импортировать" :attributes="{class:['btn-blue', 'h36']}" @click="importFeed"/>
                <FormButton label="Очистить" :attributes="{class:['btn-blue', 'h36']}" @click="clearFeed"/>
            </div>
        </div>
    </div>

    <div v-if="action!=='new'" class="row c mt-5">
        <div class="row-col w-100">
            <div class="col-12">
                <List
                    :items="categories"
                    :params="{
                        class:'list--categories',
                        type:'checkbox',
                        isChevron:true,
                        isEditor:isEditor
                    }"
                    :customActions="[
                        {title: 'Выгоды', url: (id) => `/questionnaire/${id}/questions-benefits`, callback: null,},
                        {title: 'Новости', url: (id) => `/questionnaire/${id}/news`, callback: null,}
                    ]"
                    @click="runAction"
                    @icon-click="runAction"
                    @delete="runAction"
                    :icon-item-attributes="{'data-action': 'add-item'}"
                />
            </div>
        </div>
    </div>
    <div v-else-if="action==='new'" class="row c mt-5">
        <NewCategory/>
    </div>
    <div class="row c mt-5">
        <Modal
            v-if="showModal"
            @onClose="showModal = false"
            @ok="showModal = false"
        >
            <template v-slot:body>

                <div class="modal-section modal-section-with-border">
                    <div class="form-row">
                        <div class="col">
                            <div class="form-group">
                                <FormInput
                                    v-model="editCategory.name"
                                    :attributes="{
                                        placeholder: 'Название',
                                        class: 'input-head'
                                    }"
                                />
                            </div>
                        </div>
                    </div>
                </div>

                <div class="modal-section">

                    <div class="form-row">
                        <div class="col">
                            <FormCheckbox
                                label="Выключено"
                                :style="{appearance: 'checkbox'}"
                                :attributes="{name: 'published'}"
                                v-model="disabledCheckbox"
                                wrapper-class="inline"
                            />
                        </div>
                    </div>

                    <div class="form-row">
                        <div class="col-3">
                            <label class="h6">Тип товара</label>
                            <multiselect
                                style="width: 100%;"
                                @onSelect="selectCategory"
                                v-model="editCategory.category"
                                :options="categoryTypes"
                                label="name"
                                :value="multiselectCategory"
                                placeholder="Выбор типа товара"
                                searchable
                            >
                            </multiselect>
                        </div>
                    </div>

                    <div class="form-row">
                        <div class="col">
                            <FormButton
                                v-if="newCategory"
                                @click="runAction(null, 'add')"
                                label="Добавить категорию"
                                :attributes="{class:['btn-blue', 'h36']}"
                            />
                            <FormButton
                                v-else
                                @click="runAction(null, 'save')"
                                label="Изменить категорию"
                                :attributes="{class:['btn-blue', 'h36']}"
                            />
                        </div>
                    </div>
                </div>
            </template>
        </Modal>
        <br>
    </div>

    <FormQuestionnaire
        :showModal="showQuestionnaireModal"
        :onClose="hideQuestionnaireModal"
        :onOk="saveQuestionnaire"
        :questionnaire="editedQuestionnaire"
		:additional-data="{categoryType}"
    ></FormQuestionnaire>
</div>
