<Modal
    v-if="showModal"
    @onClose="onClose"
    @onOk="onOk"
    @onDebug="debugForm"
    :buttons="[
                {
                    label: 'Закрыть',
                    event: 'onClose',
                    class: 'btn-gray h36 inline',
                },
                {
                    label: 'OK',
                    event: 'onOk',
                    class: 'btn-green h36 inline',
                },
                // {
                //     label: 'Debug',
                //     event: 'onDebug',
                // },
            ]"
    :templateButtons="
                {
                    class: 'modal-section mx-0'
                }
            "
>
    <template v-slot:body>
        <form id="questionnaire-edit" action="#" @submit.prevent="showModal = false">

            <div class="modal-section">
                <FormInput
                    :attributes="{placeholder: q.id ? 'Название опроса' : 'Новый опрос', name: 'name'}"
                    v-model="q.name"
					:label="q.id ? 'Название опроса' : 'Новый опрос'"
                />
            </div>

            <div class="modal-section">
                <FormCheckbox
                    :attributes="{placeholder: 'Включить Сценарии в Сравнении товаров', name: 'product_compare'}"
					label="Включить Сценарии в Сравнении товаров"
                    v-model="q.product_compare"
                />
            </div>

            <div class="modal-section">
                <FormInput
                    :attributes="{placeholder: 'Код анкеты из 1с', name: 'code'}"
					label="Код анкеты из 1с"
                    v-model="q.code"
                />
            </div>

			<div class="modal-section">
				<Multiselect
						key="dProductCategoriesKey"
						mode="tags"
						v-model="q.product_groups"
						placeholder="Выбери группу товаров"
						label="name"
						trackBy="id"
						valueProp="external_id"
						no-options-text="Введите текст"
						no-results-text="Нет вариантов для выбора"
						:filterResults="false"
						:minChars="2"
						:resolveOnLoad="false"
						:delay="300"
						:searchable="true"
						:options='foundProducts'
						:createTag="true"
						@search-change="findProductCategories"
				/>
			</div>

			<div v-if="categoryType==='5509'" class="modal-section">
				<Multiselect
					key="dModelLineKey"
					v-model="q.model_relations"
					mode="tags"
					placeholder="Выбери модельный ряд"
					label="name"
					trackBy="id"
					valueProp="id"
					no-options-text="Введите текст"
					no-results-text="Нет вариантов для выбора"
					:filterResults="false"
					:minChars="2"
					:resolveOnLoad="false"
					:delay="300"
					:searchable="true"
					:options='foundModels'
					:createTag="true"
					@search-change="findModel"
				/>
			</div>
            <div class="row c mt-4">
                <div class="row-col">
                    <div class="col">
                        <FormTextarea
                            label="Описание"
                            :attributes="{name: 'description', cols: '80', rows: '3'}"
                            :value="q.description"
                            v-model="q.description"
                        />
                    </div>
                </div>
            </div>
        </form>

    </template>
</Modal>
