<div v-if="!load">
    <div v-if="categoryName">
        <PageHeader>
            <div class="row c">
                <Breadcrumbs :items="[{title:'Опросник'},{title: categoryName,href:'/oprosnik-tehnika.html'}]"/>
            </div>
        </PageHeader>

        <PageTitle :title="`Опросники ${categoryName}`"/>

        <OprosnikTehnika
            :action="action"
            :categoryType="categoryType"
            :categoryTypes="categoryTypes"
        />
    </div>
    <NotFound v-if="!categoryName"/>
</div>
