const QUESTIONNAIRE_TYPE_NATIVE = 'NATIVE';
const QUESTIONNAIRE_TYPE_EXTERNAL = 'EXTERNAL';
const QUESTIONNAIRE_TYPE_CRM = 'CRM';

export default {
    // id: 0,
    code: '',
    description: null,
    external_id: '',
    kod_tovar_gruppa: '',
    kod_tovar_napravlenie: null,
    name: '',
    promo_description: null,
    promo_list_fields: null,
    promo_list_name: null,
    promo_title: null,
    removed: 0,
    show: 1,
    type: QUESTIONNAIRE_TYPE_CRM,
    model_relations: [],
    product_groups: [],
};
