<template src="./templates/create-category-form.html"></template>
<script>

	import FormInput from '@f/Input';
	import FormButton from '@f/Button';
	import List from '@c/List';

	export default {
		name: 'NewCategory',
		components: {
			FormInput,
			FormButton,
			List
		},
		props: {
            // eslint-disable-next-line vue/prop-name-casing
			parent_category: {
				type: String,
				default() {
					return null;
				}
			}
		},
		methods: {
		}
	};
</script>
