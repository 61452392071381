<div>
	<div class="row c mt-4">
		<div class="row-col">
			<div class="col">
				<FormInput style="width: 50%" :attributes="{placeholder:'Поиск по категориям и опросникам'}" />
			</div>
			<div v-if="action=='edit'" class="col-auto">
				<FormButton label="Добавить группу" :attributes="{class:['btn-blue', 'h36']}" />
			</div>
		</div>
	</div>


	<div class="row c mt-4">
		<div class="row-col">
			<div class="col-auto">
				<FormButton label="Импортировать" :attributes="{class:['btn-blue', 'h36']}" @click="importFeed" />
				<FormButton label="Очистить" :attributes="{class:['btn-blue', 'h36']}" @click="clearFeed" />
			</div>
		</div>
	</div>

	<div class="row c mt-5">
		<div class="row-col">
			<div class="col-12">
				<List :items="categories" :params="{class:'list--categories',type:'checkbox',isChevron:true,isEditor:action!=null}" />
			</div>
		</div>
	</div>
</div>
