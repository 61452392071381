<template src="./templates/categories.html"></template>
<script>

    import {
        getCategoriesTypes
    } from '@api/questionnaire';

	import PageTitle from '../../components/PageTitle';
	import Breadcrumbs from '../../components/Breadcrumbs';
	import PageHeader from '../../components/Header';
	import NotFound from '../../components/NotFound';

	import OprosnikTehnika from './components/OprosnikTehnika';

	export default {
		name: 'QuestionnaireCategories',
		components: {
			PageTitle,
			PageHeader,
			Breadcrumbs,
			OprosnikTehnika,
            NotFound
		},
        props: {
            type: {
                type: String,
                default() {
                    return '5507';
                }
            },
            action: {
                type: String,
                default() {
                    return null;
                }
            }
        },
        data() {
            return {
                categoryTypes: [],
                load: false,
            };
        },
        computed: {
            categoryName() {
                return this.categoryTypes.find((item) => (+this.$route.params.type) === item.id)?.name;
            },
            categoryType() {
                return this.$route.params.type;
            }
        },
        created() {
            this.initCategoriesTypes();
        },
        methods: {
            async initCategoriesTypes() {
                this.load = true;
                this.categoryTypes = await getCategoriesTypes();
                this.load = false;
            },
        }
	};
</script>
