<template src="../templates/FormQuestionnaire.html"></template>
<script>
import '@vueform/multiselect/themes/default.css';

import Modal from '@c/Modal/index';
import List from '@c/List/index';
import FormInput from '@f/Input';
import FormButton from '@f/Button';
import FormSelect from '@f/Select';
import FormCheckbox from '@f/Check';
import FormTextarea from '@f/Textarea';
import Editor from '@tinymce/tinymce-vue';
import Multiselect from '@vueform/multiselect';
import {
    findModelApi, getModelsByIds, findProductCategoriesApi, getProductCategoriesByCode
} from '@api/product';

const FormQuestionnaire = {
    name: 'FormQuestionnaire',
    computed: {
        q() {
            return this.questionnaire;
        },
        categoryType() {
            return this.additionalData.categoryType || null;
        }
    },
    components: {
        Modal,
        FormInput,
        FormButton,
        FormSelect,
        FormTextarea,
        FormCheckbox,
        List,
        Editor,
        Multiselect,
    },
    props: {
        questionnaire: {
            type: Object,
            default() {
                return {};
            },
        },
        additionalData: {
            type: Object,
            default() {
                return {
                    categoryType: null
                };
            },
        },
        productGroups: {
            type: Object,
            default() {
                return {};
            },
        },
        showModal: {
            type: Boolean,
            default() {
                return false;
            }
        },
        onClose: {
            type: Function,
        },
        onOk: {
            type: Function,
        },
    },
    data() {
        return {
            foundModels: [],
            foundProducts: [],
            dModelLineKey: 1,
            dProductCategoriesKey: 1,
        };
    },
    watch: {
        q() {
            this.initModels();
            this.initProductCategory();
        },
        foundModels() {
            this.dModelLineKey++;
        },
        foundProducts() {
            this.dProductCategoriesKey++;
        }
    },
    methods: {
        debugForm() {
            console.log(this.q);
            console.log(Object.fromEntries(new FormData(document.getElementById('questionnaire-edit'))));
        },

        initModels() {
            if (this.q.model_relations) {
                const relations = this.q.model_relations;
                this.q.model_relations = null;
                getModelsByIds(
                    relations,
                    (response) => {

                        this.foundModels = response;
                        const tt = setTimeout(() => {
                            clearTimeout(tt);
                            this.q.model_relations = relations;
                        }, 20);
                    }
                );
            }
        },
        findModel(query) {
            findModelApi(
                query,
                50,
                (response) => {
                    this.foundModels = response;
                }
            );
        },

        initProductCategory() {

            if (this.q.product_groups) {

                const category = this.q.product_groups;
                this.q.product_groups = null;

                getProductCategoriesByCode(
                    category,
                    (response) => {

                        this.foundProducts = response;

                        const tt = setTimeout(() => {
                            clearTimeout(tt);
                            this.q.product_groups = category;
                        }, 20);
                    }
                );
            }
        },
        findProductCategories(query) {
            findProductCategoriesApi(
                query,
                50,
                (response) => {
                    this.foundProducts = response;
                }
            );
        },
    }
};

export default FormQuestionnaire;
</script>
