import api from '../tools/api';

export const getQuestionnaire = (questionnaire_id) => api.request(
    'questionnaire',
    'get-questionnaire-details',
    {questionnaire_id}
);

export const delQuestionnaire = (questionnaire_id) => api.request(
    'questionnaire',
    'del-questionnaire',
    {questionnaire_id}
);

export const setQuestionnaire = (questionnaire) => api.request(
    'questionnaire',
    'set-questionnaire',
    {...questionnaire}
);

export const getQuestions = (questionnaire_id, published_only = false) => api.request(
    'questionnaire',
    'get-questions',
    {questionnaire_id, published_only}
);

export const getQuestion = (id) => api.request(
    'questionnaire',
    'get-question',
    {id}
);

export const getAnswersBy1CCode = (code) => api.request(
    'questionnaire',
    'get-answers-by-1c-code',
    {code}
);

export const getQuestions1CCodes = (questionnaire_id, item_type) => api.request(
    'questionnaire',
    'get-questions-1c-codes',
    {questionnaire_id, item_type}
);

export const delQuestion = (questionId, questionnaireId = null, removeType) => api.request(
    'questionnaire',
    'del-question',
    {id: questionId, questionnaireId, removeType}
);

export const setQuestion = (params) => api.request(
    'questionnaire',
    'set-question',
    {...params}
);

export const getQuestionCategories = () => api.request(
    'questionnaire',
    'get-question-categories',
    {}
);

export const getQuestionnaireCategories = (id) => api.request(
    'questionnaire',
    'get-categories',
    {id}
);

export const delQuestionnaireCategory = (id) => api.request(
    'questionnaire',
    'del-category',
    {id}
);

export const setQuestionnaireCategory = (params) => api.request(
    'questionnaire',
    'set-category',
    {...params}
);

export const getQuestionnaireCategoryFeed = (params) => api.request(
    'questionnaire',
    'get-category-feed',
    {...params}
).catch((e) => {
    console.log('e: ', e);
    return [];
});

export const setQuestionsVote = (item_id, action) => api.request(
    'questionnaire',
    'set-questions-vote',
    {item_id, action}
);

export const updateCalculateResult = (params) => api.request(
    'questionnaire',
    'calculate',
    {...params}
);

export const updateCalculatePartsResult = (params) => api.request(
    'questionnaire',
    'calculate-parts',
    {...params}
);

export const setQuestionnaireResult = (params) => api.request(
    'questionnaire',
    'set-questionnaire-result',
    {...params}
);
export const getQuestionnaireDetails = (questionnaire_id) => api.request(
    'questionnaire',
    'get-questionnaire-details',
    {questionnaire_id}
);

export const getQuestionVotes = (params) => api.request(
    'questionnaire',
    'get-questions-vote',
    {...params}
).catch((e) => {});

export const importQuestionnaireFeed = () => api.request(
    'questionnaire',
    'import'
);

export const clearQuestionnaireFeed = () => api.request(
    'questionnaire',
    'clear'
);

export const getQuestionnaireResults = (offset = 0, limit = 1000, filter = {}) => api.request(
    'questionnaire',
    'get-questionnaire-results',
    {offset, limit, filter}
).catch((e) => {
    console.log('e: ', e);
    return [];
});

export const getQuestionnaireResult = (resultCode) => api.request(
    'questionnaire',
    'get-questionnaire-result',
    {result_code: resultCode}
);

export const delQuestionnaireResult = (id) => api.request(
    'questionnaire',
    'del-questionnaire-result',
    {id}
);

export const getCategoriesTypes = () => api.request(
    'questionnaire',
    'get-categories-types',
    {}
);

export const getKPData = resultCode => api.request(
    'questionnaire',
    'get-kp-data',
    {result_code: resultCode}
);

export const getModelLinesForQuestionnaire = questionnaire_id => api.request(
    'questionnaire',
    'get-model-lines-for-questionnaire',
    {questionnaire_id}
);

export const exportQuestionsToQuestionnaires = (
    questionsIds,
    questionnaireIds,
    successHandler = () => {},
    errorHandler = () => {}
    ) => api.request(
    'questionnaire',
    'export-questions-to-questionnaires',
    {questionsIds, questionnaireIds},
    {}
).then(successHandler).catch(errorHandler);
